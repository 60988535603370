'use client';

import { useEffect, useState } from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import { CalendarDaysIcon, Check, UserIcon } from 'lucide-react';
import { useLocale, useTranslations } from 'next-intl';

import AppCounter from '@/components/atoms/AppCounter';
import {
  useCheckIn,
  useDestinationName,
  useGuestAdults,
  useGuestBikes,
  useGuestChildren,
  useGuestInfants,
  useGuestSeniors,
  useGuestYouths,
  useOriginName,
} from 'hooks/useQuerySearch';
import { IExploreNearby } from 'typings';
import { formatCheckDate } from 'utils/datesUtils';

import { FocusField } from '../molecules/SearchWidget';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { Switch } from '../ui/switch';

import AppAutocomplete from './AppAutocomplete';
import AppNearby from './AppNearby';
import AppNearbyList from './AppNearbyList';
import DiscountCardsList from './DiscountCardsList';
import AppCalendar from './AppCalendar';

const FEATURE_FLAG_DISCOUNT_CARD = false;

interface IAppSearchOptionMobileProps {
  onClose: () => void;
  field: FocusField;
  exploreNearby: IExploreNearby[];
}

type ISelectTravelersProps = {
  handleOnNext?: () => void;
  interrail?: boolean;
  displayTitle?: boolean;
};
type ISelectDateProps = {
  handleOnNext?: () => void;
  interrail?: boolean;
};

const AppSearchOptionMobile = ({
  field,
  exploreNearby,
  onClose,
}: IAppSearchOptionMobileProps) => {
  if (field == FocusField.None) {
    return null;
  }
  return (
    <div
      id="home-search-modal"
      className={`Modal Modal--breakout static flex h-full w-full z-50`}
    >
      <div className="Modal-scrollable relative flex items-center justify-center">
        <div className="Modal-container js-container fixed top-0 left-0 my-auto p-6 mx-0 h-full w-full bg-white md:static md:max-w-xl md:bg-transparent">
          <Field field={field} handleOnNext={onClose} exploreNearby={exploreNearby} />
        </div>
      </div>
    </div>
  );
};

const Field = ({ field, handleOnNext, exploreNearby }) => {
  switch (field) {
    case FocusField.Origin:
      return (
        <SelectSearchDeparture
          handleOnNext={handleOnNext}
          exploreNearby={exploreNearby}
        />
      );
    case FocusField.Destination:
      return (
        <SelectDestination handleOnNext={handleOnNext} exploreNearby={exploreNearby} />
      );
    case FocusField.Passengers:
      return <SelectTravelers handleOnNext={handleOnNext} />;
    case FocusField.Date:
      return <SelectDate handleOnNext={handleOnNext} />;
    default:
      return null;
  }
};

export const SelectSearchDeparture = ({ handleOnNext, exploreNearby }) => {
  const t = useTranslations('Header.search');

  const [originName] = useOriginName();

  const [locAutocomplete, setLocAutocomplete] = useState(false);
  const [typedOrigin, setTypedOrigin] = useState(originName);
  const [enter, setEnter] = useState(false);

  useEffect(() => {
    //select input and openkeyboard when step is active
    const input = document.getElementById('home-search-origin-input') as HTMLInputElement;
    input?.focus();
    input.select();
  }, []);

  //TODO: a comment (or even better a change of code) should be made to clarify how this works
  //Not touching it for now as I might break it
  useEffect(() => {
    if (typedOrigin !== originName && originName !== '') {
      setLocAutocomplete(true);
    } else {
      setLocAutocomplete(false);
    }
  }, [typedOrigin, originName]);

  useEffect(() => {
    const input = document.getElementById('home-search-origin-input') as HTMLInputElement;
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && locAutocomplete) {
        setEnter(true);
        e.preventDefault();
      }
    };
    input.addEventListener('keydown', handleKeyDown);
    return () => {
      input.removeEventListener('keydown', handleKeyDown);
    };
  }, [locAutocomplete]);

  return (
    <div className="Start-panel Start-panel--from js-tabgroup w-full pb-4 md:px-8">
      <div className="Start-header mb-4 flex items-center">
        <h2 className="Start-heading Start-heading--small static mr-auto flex w-full flex-row text-lg font-bold">
          {t('from.explicitTitle')}{' '}
        </h2>
      </div>
      <input id="origin" type="hidden" name="panel" value="origin" />
      <div
        id="home-search-origin"
        className="Autocomplete w-full whitespace-normal text-left"
        data-nanocomponent="ncid-ec20"
        data-onloadid4z77gu34egr="o66"
      >
        <div className="Autocomplete-control relative m-0 flex items-center p-0">
          <SearchIcon className="hidden h-6 w-6 text-muted-foreground sm:block" />
          <Input
            type="text"
            name="autocomplete"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            id="home-search-origin-input"
            placeholder={t('from.mobileplaceholder')}
            className="Autocomplete-input js-text js-home-search-origin mr-4 rounded-lg bg-secondary px-1.5 outline-none sm:ml-3"
            data-nanocomponent="ncid-94b5"
            data-onloadid4z77gu34egr="o65"
            value={typedOrigin}
            onChange={({ target }) => setTypedOrigin(target.value)}
          />
          <Button name="panel" size="xs" variant="secondary" onClick={handleOnNext}>
            <span className="Autocomplete-label text-xs">{t('cancelBtn')}</span>
          </Button>
        </div>

        <ol className="Autocomplete-destinations m-0 mt-3 list-none p-0">
          {locAutocomplete ? (
            <>
              <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                Results
              </h3>
              <AppAutocomplete
                onEnter={enter}
                locationName={typedOrigin}
                changeOrigin={true}
                onClick={handleOnNext}
              />
            </>
          ) : (
            <>
              <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                {t('popular')}
              </h3>
              <AppNearbyList
                numberOfItems={5}
                changeOrigin={true}
                onClick={handleOnNext}
                exploreNearby={exploreNearby}
                include={['Paris', 'Bruxelles', 'Lyon', 'Nantes', 'Marseille']}
              />
            </>
          )}
        </ol>
      </div>
    </div>
  );
};

//TODO: merge those 2 SelectSearchDeparture & SelectDestination
const SelectDestination = ({ handleOnNext, exploreNearby }) => {
  const t = useTranslations('Header.search');
  const [autocomplete, setAutocomplete] = useState(false);

  const [destinationName, setDestinationName] = useDestinationName();
  const [typedDestination, setTypedDestination] = useState(destinationName);
  const [enter, setEnter] = useState(false);

  useEffect(() => {
    //select input and openkeyboard when step is active
    const input = document.getElementById('home-search-dest-input') as HTMLInputElement;
    input?.focus();
    input.select();
  }, []);

  //TODO: a comment (or even better a change of code) should be made to clarify how this works
  //Not touching it for now as I might break it
  useEffect(() => {
    if (typedDestination !== destinationName && destinationName !== '') {
      setAutocomplete(true);
    } else {
      setAutocomplete(false);
    }
  }, [typedDestination, destinationName]);

  //if input is entered and enter pressed use the first autocomplete
  useEffect(() => {
    const input = document.getElementById('home-search-dest-input') as HTMLInputElement;
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && autocomplete) {
        setEnter(true);
        e.preventDefault();
      }
    };
    input.addEventListener('keydown', handleKeyDown);
    return () => {
      input.removeEventListener('keydown', handleKeyDown);
    };
  }, [autocomplete]);

  return (
    <div className="Start-panel Start-panel--from js-tabgroup w-full pb-4 md:px-8">
      <div className="Start-header mb-4 flex items-center">
        <h2 className="Start-heading Start-heading--small static mr-auto flex w-full flex-row text-lg font-bold">
          {t('to.explicitTitle')}{' '}
        </h2>
      </div>
      <input id="destination" type="hidden" name="panel" value="destination" />
      <div
        id="home-search-destination"
        className="Autocomplete w-full whitespace-normal text-left"
        data-nanocomponent="ncid-ec20"
        data-onloadid4z77gu34egr="o66"
      >
        <div className="Autocomplete-control relative m-0 flex items-center p-0">
          <SearchIcon className="hidden h-6 w-6 text-muted-foreground sm:block" />
          <Input
            type="text"
            name="autocomplete"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            id="home-search-dest-input"
            placeholder={t('to.mobileplaceholder')}
            className="Autocomplete-input js-text js-home-search-origin mr-4 rounded-lg bg-secondary px-1.5 outline-none sm:ml-3"
            data-nanocomponent="ncid-94b5"
            data-onloadid4z77gu34egr="o65"
            value={typedDestination}
            onChange={({ target }) => setTypedDestination(target.value)}
          />
          <Button
            type="button"
            name="panel"
            size="xs"
            variant="secondary"
            onClick={handleOnNext}
          >
            {' '}
            <span className="Autocomplete-label text-xs">{t('cancelBtn')}</span>
          </Button>
        </div>

        <ol className="Autocomplete-destinations m-0 mt-3 list-none p-0">
          {autocomplete ? (
            <>
              <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                Results
              </h3>
              <AppAutocomplete
                onEnter={enter}
                locationName={typedDestination}
                onClick={handleOnNext}
              />
            </>
          ) : (
            <>
              <div className="max-sm:hidden">
                <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                  {t('to.inspiration.headline')}
                </h3>
                <AppNearby
                  data={{
                    name: t('to.inspiration.title'),
                    stationName: t('to.inspiration.stationName'),
                    img: '/assets/maps/mapIcon.png',
                    country: t('to.inspiration.subtitle'),
                  }}
                  isSmall
                  outline
                  disabled
                  onClick={async () => {
                    handleOnNext();
                    await setDestinationName('Anywhere');
                  }}
                />
              </div>

              <h3 className="Autocomplete-heading m-0 py-2 text-sm font-light text-muted-foreground">
                {t('popular')}
              </h3>
              <AppNearbyList
                numberOfItems={5}
                exclude={destinationName}
                onClick={handleOnNext}
                exploreNearby={exploreNearby}
                include={['Wien', 'Venice', 'Berlin', 'Amsterdam', 'Budapest']}
                isSmall
              />
            </>
          )}
        </ol>
      </div>
    </div>
  );
};

const SelectTravelers = ({
  handleOnNext,
  interrail = false,
  displayTitle = true,
}: ISelectTravelersProps) => {
  const [validable, setValidable] = useState(true);
  const t = useTranslations('Header.search');
  const [discount, setDiscount] = useState(false);

  const [guestAdults, setGuestAdults] = useGuestAdults();
  const [guestChildren, setGuestChildren] = useGuestChildren();
  const [guestInfants, setGuestInfants] = useGuestInfants();
  const [guestYouths, setGuestYouths] = useGuestYouths();
  const [guestSeniors, setGuestSeniors] = useGuestSeniors();
  const [guestBikes, setGuestBikes] = useGuestBikes();

  const totalGuests = () =>
    guestAdults + guestChildren + guestInfants + guestYouths + guestSeniors;

  useEffect(() => {
    if (totalGuests() === 0) {
      setValidable(false);
    } else {
      setValidable(true);
    }
  }, [guestAdults, guestChildren, guestYouths, guestSeniors, guestInfants]);

  return (
    <div className="Start-panel Start-panel--passengers js-tabgroup w-full md:min-w-[21rem]">
      <div className="Start-header flex items-center">
        {displayTitle && (
          <h2 className="Start-heading Start-heading--small mb-3 static mr-auto flex w-full flex-row font-bold">
            <UserIcon className="mr-2 h-6 w-6 " />
            {totalGuests()} {t('travelers.title', { count: totalGuests() })}
          </h2>
        )}
        {!interrail && displayTitle && (
          <Button
            onClick={validable ? handleOnNext : undefined}
            name="panel"
            type="button"
            size="sm"
            disabled={!validable}
            className="Button Button--xs"
          >
            <Check className="mr-2 h-4 w-4" /> {t('validateBtn')}
          </Button>
        )}
      </div>
      <div
        className="Passengers Passengers--md relative m-0 mx-auto flex min-h-full flex-col gap-4 p-0"
        id="ncid-b24a"
        data-nanocomponent="ncid-b24a"
      >
        <div className="Passengers-main flex-1 justify-center">
          <div>
            <div className="flex items-center justify-between py-4">
              <div className="flex-grow">
                <h2 className="font-medium">{t('travelers.adults.plural')}</h2>
                <p className="text-sm leading-4 text-gray-300">
                  {t(`travelers.adults.${interrail ? 'interrail_' : ''}subtitle`)}
                </p>
              </div>
              <AppCounter value={guestAdults} setValue={setGuestAdults} maxValue={16} />
            </div>
          </div>
          <DiscountCardsList
            travelers={guestAdults}
            discountEnabled={discount}
            travelerType="Adult"
          />

          <div>
            <div className="flex items-center justify-between border-t-2 border-secondary py-4">
              <div className="flex-grow">
                <h2 className="font-medium">{t('travelers.youths.plural')}</h2>
                <p className="text-sm leading-4 text-gray-300">
                  {t(`travelers.youths.${interrail ? 'interrail_' : ''}subtitle`)}
                </p>
              </div>
              {interrail && (
                <Badge variant="secondary" className="mr-5">
                  -25%
                </Badge>
              )}
              <AppCounter value={guestYouths} setValue={setGuestYouths} maxValue={5} />
            </div>
          </div>

          <DiscountCardsList
            travelers={guestYouths}
            discountEnabled={discount}
            travelerType="Youths"
          />

          <div>
            <div className="flex items-center justify-between border-t-2 border-secondary py-4">
              <div className="flex-grow">
                <h2 className="font-medium">{t('travelers.children.plural')}</h2>
                <p className="text-sm leading-4 text-gray-300">
                  {t(`travelers.children.${interrail ? 'interrail_' : ''}subtitle`)}
                </p>
              </div>
              {interrail && (
                <Badge variant="secondary" className="mr-5">
                  {t('travelers.free')}
                </Badge>
              )}
              <AppCounter
                value={guestChildren}
                setValue={setGuestChildren}
                maxValue={5}
              />
            </div>
          </div>

          <DiscountCardsList
            travelers={guestChildren}
            discountEnabled={discount}
            travelerType="Child"
          />

          {!interrail && (
            <div>
              <div className="flex items-center justify-between border-t-2 border-secondary py-4">
                <div className="flex-grow">
                  <h2 className="font-medium">{t('travelers.infants.plural')}</h2>
                  <p className="text-sm leading-4 text-gray-300">
                    {t('travelers.infants.subtitle')}
                  </p>
                </div>
                {interrail && (
                  <Badge variant="secondary" className="mr-5">
                    Free
                  </Badge>
                )}
                <AppCounter
                  value={guestInfants}
                  setValue={setGuestInfants}
                  maxValue={5}
                />
              </div>
            </div>
          )}

          {!interrail && (
            <div>
              <div className="flex items-center justify-between border-t-2 border-secondary py-4">
                <div className="flex-grow">
                  <h2 className="font-medium">{t('travelers.bikes.plural')}</h2>
                  <p className="text-sm leading-4 text-gray-300">
                    {t('travelers.bikes.subtitle')}
                  </p>
                </div>
                <AppCounter
                  value={guestBikes}
                  setValue={setGuestBikes}
                  maxValue={2 * guestAdults}
                />
              </div>
            </div>
          )}

          {interrail && (
            <div>
              <div className="flex items-center justify-between border-t-2 border-secondary py-4">
                <div className="flex-grow">
                  <h2 className="font-medium">{t('travelers.seniors.plural')}</h2>
                  <p className="text-sm leading-4 text-gray-300">
                    {t('travelers.seniors.subtitle')}
                  </p>
                </div>
                {interrail && (
                  <Badge variant="secondary" className="mr-5">
                    -10%
                  </Badge>
                )}

                <AppCounter
                  value={guestSeniors}
                  setValue={setGuestSeniors}
                  maxValue={6}
                />
              </div>
            </div>
          )}

          {FEATURE_FLAG_DISCOUNT_CARD && (
            <div>
              <div className="flex flex-row border-t-2 border-secondary gap-9 mb-3 items-center pt-4">
                <div className="flex flex-row items-center gap-2 w-full">
                  <div className="shrink-0">
                    <svg
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      color="#425486"
                    >
                      <path
                        d="M15.375 15.375c2.025 0 3.825-.9 5.063-2.25H21v6.75C21 20.55 20.55 21 19.875 21H4.125C3.45 21 3 20.55 3 19.875v-6.75h7.313c1.237 1.35 3.037 2.25 5.062 2.25zm-6.75-6.75c0 .787.113 1.575.338 2.25H3v-2.25C3 7.95 3.45 7.5 4.125 7.5h4.613c-.113.338-.113.787-.113 1.125zm6.75 5.625c-3.037 0-5.625-2.475-5.625-5.625S12.225 3 15.375 3 21 5.475 21 8.625s-2.475 5.625-5.625 5.625zm1.913-8.662l-4.726 5.174.788.788 4.725-5.175-.787-.787zm.337 6.3a1.013 1.013 0 1 0 0-2.026 1.013 1.013 0 0 0 0 2.025zm-4.5-4.5a1.013 1.013 0 1 0 0-2.026 1.013 1.013 0 0 0 0 2.026z"
                        fill="currentColor"
                        fillRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <span className="text-primary">
                    <span>{t('cards.addCardsSwitch')}</span>
                  </span>
                  <div className="mt-1 grow text-right">
                    <div
                      data-component="toggle"
                      data-e2e="discountCardToggleBtn"
                      className="sc-1u499ex-0 iEpCYT"
                    >
                      <div className="react-toggle react-toggle--checked">
                        <Switch
                          id="checkbox"
                          checked={discount}
                          onCheckedChange={(value) => setDiscount(value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className={`${validable ? 'hidden' : ''} Passengers-footer mt-2 mr-6 flex w-fit flex-row justify-end gap-3 self-end whitespace-normal rounded-lg bg-red-100 p-3 text-right font-medium text-primary`}
          >
            <p>{t('travelers.empty')}</p> <p className=" mr-1 h-6 text-2xl">👆</p>
          </div>
          {discount && guestChildren > 0 && (
            <div className="Passengers-footer mt-2 max-w-xs flex-wrap whitespace-normal font-light text-slate-400">
              <p>{t('travelers.childrenAge')}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SelectDate = ({ handleOnNext, interrail }: ISelectDateProps) => {
  const [checkIn] = useCheckIn();
  const t = useTranslations('Header.search');
  const locale = useLocale();
  return (
    <>
      {/* Date Panel */}
      <div className="Start-panel Start-panel--date js-tabgroup w-full pb-0 -mb-2 md:min-w-[21rem]">
        {!interrail && (
          <div className="Start-header mb-2 flex items-center">
            <h2 className="Start-heading static mr-auto flex w-full font-bold">
              <CalendarDaysIcon className="mr-2 h-6 w-6" />
              {checkIn ? formatCheckDate(checkIn, locale) : t('checkIn.optionTitle')}
            </h2>
            <Button
              name="panel"
              size="xs"
              type="button"
              variant="secondary"
              onClick={handleOnNext}
            >
              <span className="text-xs">{t('cancelBtn')}</span>
            </Button>
          </div>
        )}
        <div
          className="Datepicker flex w-full justify-center"
          id="ncid-6827"
          data-nanocomponent="ncid-6827"
          data-onloadid4z77gu34egr="o90"
        >
          <AppCalendar
            className="max-w-sm md:pb-0 scale-95 -mx-6 -my-4"
            months={1}
            handle={handleOnNext}
          />

          <div className="Datepicker-message">
            <div className="Datepicker-status Datepicker-status--uncertain hidden text-secondary">
              Tickets may already be available
            </div>
            <div className="Datepicker-status Datepicker-status--unlikely hidden text-secondary-foreground">
              Tickets may not yet be available
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppSearchOptionMobile;
export { SelectTravelers, SelectDate };
